import { Button, Popconfirm } from 'antd';
import { fetcher } from '../../../extras/fetcher';


interface Props {
  getData: () => void;
}

export const ButtonRemoveDhl = (props: Props) => {


  const confirm = async () => {
    const data = await fetcher('courier/dhl', 'DELETE');

    if(data.status === 403) {
      return;
    }


    props.getData();

  }


  return (
    <Popconfirm title={'Usuwanie DHL'} description="Czy na pewno chcesz usunąć wszystkie DHL-e?"
                okText="Tak"
                cancelText="Nie"
                onConfirm={confirm}
    >
      <Button>Usuń z komentarzem "DHL"</Button>
    </Popconfirm>
  );
}
