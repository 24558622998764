import React, { useEffect, useState } from 'react';
import './App.css';
import 'antd/dist/reset.css';
import { Layout } from 'antd';
import { MainSider } from './components/MainSider/MainSider';
import { MainHeader } from './components/MainHeader/MainHeader';
import { MainFooter } from './components/MainFooter/MainFooter';
import { MainContent } from './components/MainContent/MainContent';
import { HomePage } from './components/Page/HomePage/HomePage';
import { LoginPage } from './components/Page/LoginPage/LoginPage';
import { LoggedInContext } from './contexts/loggedIn.context';
import { fetcher } from './extras/fetcher';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { NotFoundPage } from './components/Page/NotFoundPage/NotFoundPage';
import { StanReg } from './components/Page/Ikea/Stan/StanReg/StanReg';
import { TableOneRack } from './components/Page/Ikea/Stan/StanReg/TableOneRack';
import { StanKod } from './components/Page/Ikea/Stan/StanKod/StanKod';
import { TableOneCode } from './components/Page/Ikea/Stan/StanKod/TableOneCode';
import { SelectedMenuContext } from './contexts/selectedMenu.context';
import { Trip } from './components/Page/Ikea/Trip/Trip';
import { OneTrip } from './components/Page/Ikea/Trip/OneTrip';
import { OneDelivery } from './components/Page/Ikea/Trip/OneDelivery';
import { SerialsOfPosition } from './components/Page/Ikea/Trip/SerialsOfPosition';
import { TableCollected } from './components/Page/Ikea/Trip/TableCollected';
import { UsersTerm } from './components/Page/Admin/UsersTerm/UsersTerm';
import { OneUserTerm } from './components/Page/Admin/UsersTerm/OneUserTerm';
import { Users } from './components/Page/Admin/Users/Users';
import { OneUser } from './components/Page/Admin/Users/OneUser';
import { ResetPassword } from './components/Page/ResetPassword/ResetPassword';
import { Profile } from './components/Page/Profile/Profile';
import { UsersInterface } from './types/users.interface';
import { StanReg2 } from './components/Page/Ikea/Stan/StanReg/StanReg2';
import { HistoriaSSCC } from './components/Page/Ikea/Historia/HistoriaSSCC';
import { HistoriaUDC } from './components/Page/Ikea/Historia/HistoriaUDC';
import { StatsReg } from './components/Page/Ikea/Stats/StatsReg';
import { StatsMag } from './components/Page/Ikea/Stats/StatsMag';
import { useWindowDimensions } from './hooks/useWindowDimensions';
import { MenuLeft } from './components/MenuLeft/MenuLeft';
import { Lockers } from './components/Page/Person/Lockers';
import { Employees } from './components/Page/Person/Employees';
import { OneEmployee } from './components/Page/Person/OneEmployee';
import { NavList } from './components/Page/Nav/NavList/NavList';
import { OneNav } from './components/Page/Nav/NavList/OneNav';
import { OneNavCode } from './components/Page/Nav/NavList/OneNavCode';
import { InboundCodes } from './components/Page/Inbound/InboundCodes';
import { InboundOneCode } from './components/Page/Inbound/InboundOneCode';
import { Photos } from './components/Page/Photos/Photos';
import { Courier } from './components/Page/Courier/Courier';


export const App = () => {

  const [collapsed, setCollapsed] = useState(false);
  const [theme, setTheme] = useState<'light' | 'dark'>('light');

  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const [user, setUser] = useState<UsersInterface | null>(null);
  const [selectedMenuKey, setSelectedMenuKey] = useState<string[]>(['1']);

  const linkParams = useParams();
  const searchParams = new URLSearchParams(document.location.search);

  const { windowWidth } = useWindowDimensions();


  const handleSetLoggedIn = async (value: boolean) => {
    if(value) {
     // await cookies.remove('token');
      setUser(null);
    }
    await setLoggedIn(value);
  }

  useEffect(() => {
    (async () => {
      const res = await fetcher('auth/test','HEAD');
      if(res.status === 204) {

          const getUser = await fetcher('user/current');
          if(getUser.status === 200) {
            setUser(getUser.result);
          }


        setLoggedIn(true);
      } else if(res.status ===401 || res.status === 403 ) {
        // tutaj będziemy usuwali wszystkie zapisane cookie i inne dane
        setLoggedIn(false);
        setUser(null);
      }
    })();


  },[loggedIn]);


  //console.log(window.location.pathname.split('/')[1]);

  if(window.location.pathname.split('/')[1] === 'user-password-renew') {
   return (
     <ResetPassword resetToken={window.location.pathname.split('/')[2]} />
   )
  }

  if(window.location.pathname.split('/')[1] === 'welcome') {
    return (
      <ResetPassword resetToken={window.location.pathname.split('/')[2]} />
    )
  }


  if(!loggedIn) {
    return (
      <LoginPage handleSetLoggedIn={handleSetLoggedIn}/>
    );
  }


  return (
    <LoggedInContext.Provider value={{loggedIn, user, setLoggedIn: handleSetLoggedIn}}>
      <div className="App">
        <SelectedMenuContext.Provider value={{selectedKey: selectedMenuKey, setSelectedKey: (value ) => setSelectedMenuKey(value)}}>
          <Router>
            <Layout className="layout-transparent" style={{ minHeight: '100vh' }}>
              {windowWidth >= 768 && <MainSider collapsed={collapsed} handleSetCollapsed={setCollapsed} theme={theme}/>}
              <Layout className="site-layout">
                {windowWidth >= 768 && <MainHeader theme={theme}/>}
                <Routes>
                  <Route path="/" element={<MainContent content={HomePage}/>}/>

                  <Route path="/profile/settings/" element={<MainContent content={Profile}/>}/>

                  <Route path="/trip/" element={<MainContent content={Trip}/>}/>
                  <Route path="/trip/:trip" element={<MainContent content={OneTrip}/>}/>
                  <Route path="/trip/:trip/all-delivery" element={<MainContent content={OneTrip}/>}/>
                  <Route path="/trip/:trip/:delivery" element={<MainContent content={OneDelivery}/>}/>
                  <Route path="/trip/:trip/:delivery/collected" element={<MainContent content={TableCollected}/>}/>
                  <Route path="/trip/:trip/:delivery/collected/:code" element={<MainContent content={TableCollected}/>}/>
                  <Route path="/trip/:trip/:delivery/serials" element={<MainContent content={SerialsOfPosition}/>}/>



                  <Route path="/stock/rack" element={<MainContent content={StanReg}/>}/>
                  <Route path="/stock/rack/:rackName" element={<MainContent content={TableOneRack}/>}/>

                  <Route path="/stock/rack-total" element={<MainContent content={StanReg2}/>}/>

                  <Route path="/stock/material" element={<MainContent content={StanKod}/>}/>
                  <Route path="/stock/material/:code" element={<MainContent content={TableOneCode}/>}/>


                  <Route path="/stats/reg" element={<MainContent content={StatsReg}/>}/>
                  <Route path="/stats/mag" element={<MainContent content={StatsMag}/>}/>

                  <Route path="/history/sscc/:sscc" element={<MainContent content={HistoriaSSCC}/>}/>
                  <Route path="/history/udc/:udc" element={<MainContent content={HistoriaUDC}/>}/>


                  <Route path="/nav/list/" element={<MainContent content={NavList}/>}/>
                  <Route path="/nav/list/:navId" element={<MainContent content={OneNav}/>}/>
                  <Route path="/nav/list/:navId/code/:code?" element={<MainContent content={OneNavCode}/>}/>


                  <Route path="/inbound/code/" element={<MainContent content={InboundCodes}/>}/>
                  <Route path="/inbound/code/:code" element={<MainContent content={InboundOneCode}/>}/>


                  <Route path="/person/lockers" element={<MainContent content={Lockers}/>}/>
                  <Route path="/person/employees/:employeeId" element={<MainContent content={OneEmployee}/>}/>
                  <Route path="/person/employees" element={<MainContent content={Employees}/>}/>

                  <Route path="/photos" element={<MainContent content={Photos}/>}/>
                  <Route path="/courier" element={<MainContent content={Courier}/>}/>


                  <Route path="/admin/users-term" element={<MainContent content={UsersTerm}/>}/>
                  <Route path="/admin/users-term/:userTermId" element={<MainContent content={OneUserTerm}/>}/>

                  <Route path="/admin/users" element={<MainContent content={Users}/>}/>
                  <Route path="/admin/users/:userId" element={<MainContent content={OneUser}/>}/>

                  <Route path="*" element={<MainContent content={NotFoundPage}/>}/>
                </Routes>
                {windowWidth < 768  && <MenuLeft collapsed={false} theme={theme}/>}
                <MainFooter/>
              </Layout>
            </Layout>
          </Router>
        </SelectedMenuContext.Provider>
      </div>
    </LoggedInContext.Provider>
  );
}
