import { Button, message, Popconfirm } from 'antd';
import React from 'react';
import { fetcher } from '../../../extras/fetcher';
import { DeleteOutlined } from '@ant-design/icons';

interface Props {
  id: number;
  tripNr: number;
  getData: () => void;
}

export const DeleteTrip = (props: Props) => {

  const [messageApi, contextHolder] = message.useMessage();

  const onConfirm = async (e?: React.MouseEvent) => {
    const {result, status} = await fetcher(`courier/${props.id}`, 'DELETE');

    if(result.success) {

      messageApi.open({
        type: 'success',
        content: `Trip ${props.tripNr} został usunięty!`,
      });

      props.getData();

      return;
    }

    messageApi.open({
      type: 'error',
      content: result.error ? result.error : 'Nieznany błąd!',
    });

  };

  return (<>
      {contextHolder}
      <Popconfirm  placement="leftTop" title={`Na pewno chcesz usunąć trip ${props.tripNr}`} okText={'Tak'} cancelText={'Nie'}
                   onConfirm={onConfirm}>
        <Button icon={<DeleteOutlined />} />

      </Popconfirm>
    </>
  )

}
