import { CourierWithErrorsInterface } from '../../../types/CourierWithErrors.interface';
import { TableHeader } from '../Ikea/Stan/TableHeader';
import { Button, Table } from 'antd';
import React, { useState } from 'react';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { ColumnsType } from 'antd/es/table';
import { myNumberSorter, myStringSorter } from '../../../extras/sorters';
import { LogoSchenkerSVG } from './LogoSchenkerSvg';
import { LogoDhlSVG } from './LogoDhlSVG';

import './Courier.css';
import { PlusOutlined } from '@ant-design/icons';
import { ModalAddTripToCourierError } from './ModalAddTripToCourierError';
import { CourierCurrentInterface } from '../../../types/courierCurrent.interface';


interface Props {
  courierWithErrors: CourierWithErrorsInterface[];
  loading: boolean;
  getData: () => void;
  handleAddTripModalOpened: (opened: boolean) => void;
  handleSetAddTripData: (trip: number, name: string) => void;
  setCourierWithErrorsData: (data: CourierWithErrorsInterface[]) => void;
  handleSetData: (data: CourierCurrentInterface[]) => void;
}

export const CourierWithErrors = (props: Props) => {

  const { windowHeight } = useWindowDimensions();

  const [modalAddTripToCourierErrorOpened, setModalAddTripToCourierErrorOpened] = useState<boolean>(false);
  const [fileData, setFileData] = useState<{
    nr: number;
    type: 1 | 2;
  }>({
    nr: 0,
    type: 1,
  });

  const openModal = (nr: number, type: 1 | 2) => {
    setFileData({
      nr: nr,
      type: type,
    });
    setModalAddTripToCourierErrorOpened(true);
  }


  const columns: ColumnsType<CourierWithErrorsInterface> = [
    {
      title: 'Pliki',
      width: 150,
      dataIndex: 'fileNames',
      sorter: (a, b) => myStringSorter(a.fileNames.join(), b.fileNames.join()),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => record.fileNames.join(', '),
    },

    {
      title: 'Trip',
      width: 100,
      dataIndex: 'trip',
      sorter: (a, b) => myNumberSorter(a.trip ? a.trip : 0, b.trip ? b.trip : 0),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => <div>{record.trip} {!!record.trip ? <Button onClick={() => props.handleSetAddTripData(Number(record.trip), !!record.fileNames[0] ? record
        .fileNames[0]
        .replace(/\.pdf$/, '') // Remove the .pdf extension
        .replace(/label/i, '') : '' // Remove any instance of "label", case-insensitive
      )} shape="circle" icon={<PlusOutlined />} /> : record.nr ? <Button onClick={() => openModal(record.nr, record.type as 1|2)} shape="circle" icon={<PlusOutlined />} /> : ''}

      </div>,

    },

    {
      title: 'Typ',
      width: 50,
      dataIndex: 'type',
      align: 'center',
      sorter: (a, b) => myNumberSorter(a.type ? a.type : 0, b.type ? b.type : 0),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => record.type === 1 ? <LogoSchenkerSVG/> : record.type === 2 ? <LogoDhlSVG /> : '',
    },

    {
      title: 'Nr Wysyłki',
      width: 100,
      dataIndex: 'nr',
      sorter: (a, b) => myNumberSorter(a.nr ? a.nr : 0, b.nr ? b.nr : 0),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Delivery',
      width: 100,
      dataIndex: 'delivery',
      render: (value, record) => record.deliveries.map((str, index) => <div
        key={index}>{str.trim()}</div>),
    },

    ];

  return <><Table
    className={'courier-table'}
    rowKey={'nr'}
    title={(records) => <TableHeader getData={props.getData} selectedCount={0}/>} columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={props.loading} showHeader={true} bordered={true} dataSource={props.courierWithErrors} style={{opacity: 0.9}} size={'small'} />

  <ModalAddTripToCourierError open={modalAddTripToCourierErrorOpened} handleSetModalOpened={setModalAddTripToCourierErrorOpened} handleSetData={props.handleSetData} handleSetCourierWithErrorsData={props.setCourierWithErrorsData} fileData={fileData} />
</>

}
