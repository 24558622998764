export const LogoSchenkerSVG: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74"
    height="13"
    version="1.0"
    viewBox="0 0 370 65"
  >
    <defs>
      <clipPath id="clipPath2432" clipPathUnits="userSpaceOnUse">
        <path d="M 404.504,732.472 L 575.433,732.472 L 575.433,822.48 L 404.504,822.48 L 404.504,732.472 z" />
      </clipPath>
      <clipPath id="clipPath2480" clipPathUnits="userSpaceOnUse">
        <path d="M 404.504,793.418 L 575.428,793.418 L 575.428,822.48 L 404.504,822.48 L 404.504,793.418 z" />
      </clipPath>
    </defs>
    <g transform="translate(-45.315482,-306.34158)">
      <g transform="matrix(1.7085441,0,0,1.7085441,15.527082,-229.0139)">
        <g transform="matrix(1.25,0,0,-1.25,-486.74334,1342.2987)" clipPath="url(#clipPath2432)">
          <g transform="translate(441.8902,822.4804)">
            <path d="M 0,0 L -33.214,0 C -35.497,0 -37.386,-1.805 -37.386,-4.138 L -37.386,-24.88 C -37.386,-27.212 -35.497,-29.062 -33.214,-29.062 L 0,-29.062 C 2.241,-29.062 4.131,-27.212 4.131,-24.88 L 4.131,-4.138 C 4.131,-1.805 2.241,0 0,0" style={{ fill: '#ed1c24', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
          <g transform="translate(442.9886,797.6008)">
            <path d="M 0,0 C 0,-0.661 -0.439,-1.19 -1.098,-1.19 L -34.313,-1.19 C -34.971,-1.19 -35.454,-0.661 -35.454,0 L -35.454,20.742 C -35.454,21.402 -34.971,21.93 -34.313,21.93 L -1.098,21.93 C -0.439,21.93 0,21.402 0,20.742 L 0,0 z" style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
          <g transform="translate(436.0031,804.0316)">
            <path d="M 0,0 C 0,1.937 -1.23,2.377 -3.031,2.377 L -4.701,2.377 L -4.701,-2.51 L -3.075,-2.51 C -1.449,-2.51 0,-1.982 0,0 M -4.701,5.899 L -3.118,5.899 C -1.625,5.899 -0.528,6.56 -0.528,8.189 C -0.528,9.995 -1.934,10.48 -3.427,10.48 L -4.701,10.48 L -4.701,5.899 z M -1.845,-5.594 L -9.225,-5.594 L -9.225,13.43 L -1.493,13.43 C 2.154,13.43 4.129,11.932 4.129,8.365 C 4.129,6.515 2.592,5.195 0.922,4.358 C 3.252,3.699 4.833,2.287 4.833,-0.089 C 4.833,-3.965 1.582,-5.594 -1.845,-5.594" style={{ fill: '#ed1c24', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
          <g transform="translate(420.2326,807.642)">
            <path d="M 0,0 C 0,3.832 -0.397,6.824 -4.525,6.824 L -5.449,6.824 L -5.449,-6.121 L -3.824,-6.121 C -1.407,-6.121 0,-4.184 0,0 M -3.032,-9.204 L -10.018,-9.204 L -10.018,9.819 L -3.032,9.819 C 1.888,9.819 4.523,6.737 4.523,0.396 C 4.523,-5.109 2.766,-9.16 -3.032,-9.204" style={{ fill: '#ed1c24', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
          <g transform="translate(463.3661,808.1006)">
            <path d="M 0,0 C 0.003,-0.001 -0.26,0.11 -0.26,0.11 C -1.936,0.828 -2.003,0.854 -2.399,1.135 C -2.949,1.521 -3.132,1.819 -3.132,2.32 C -3.132,3.204 -2.394,3.655 -0.933,3.655 C 0.358,3.655 1.302,3.435 2.803,2.776 L 3.038,2.675 L 3.038,6.048 L 2.927,6.09 C 1.485,6.611 0.278,6.836 -1.107,6.836 C -4.831,6.836 -6.965,5.048 -6.965,1.932 C -6.965,-0.416 -5.901,-1.763 -3.17,-2.873 C -0.266,-4.078 0.009,-4.38 0.009,-5.26 C 0.009,-6.286 -0.858,-6.81 -2.563,-6.81 C -4.036,-6.81 -5.149,-6.518 -6.811,-5.698 L -7.054,-5.578 L -7.054,-8.952 L -6.957,-8.999 C -5.412,-9.704 -4.065,-9.99 -2.302,-9.99 C 1.771,-9.99 4.016,-8.202 4.016,-4.956 C 4.016,-2.496 3.001,-1.245 0,0" style={{ fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
          <g transform="translate(480.8019,802.0853)">
            <path d="M 0,0 C -1.537,-0.571 -2.538,-0.771 -3.833,-0.771 C -6.526,-0.771 -7.575,0.666 -7.575,4.361 C -7.575,8.217 -6.556,9.648 -3.81,9.648 C -2.538,9.648 -1.425,9.423 -0.092,8.901 L 0.137,8.81 L 0.137,12.148 L 0.028,12.19 C -1.222,12.643 -2.475,12.851 -3.919,12.851 C -6.162,12.851 -8.07,12.222 -9.44,11.027 C -11.154,9.537 -11.952,7.414 -11.952,4.339 C -11.952,1.731 -11.408,-0.135 -10.241,-1.526 C -8.883,-3.129 -6.743,-3.975 -4.051,-3.975 C -2.544,-3.975 -1.099,-3.67 0.128,-3.091 L 0.225,-3.047 L 0.225,0.084 L 0,0 z" style={{ fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
          <g transform="translate(493.2924,814.5656)">
            <path d="M 0,0 L 0,-6.255 L -5.747,-6.255 L -5.747,0 L -9.908,0 L -9.908,-16.13 L -5.747,-16.13 L -5.747,-9.481 L 0,-9.481 L 0,-16.13 L 4.161,-16.13 L 4.161,0 L 0,0 z" style={{ fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
          <g transform="translate(504.7097,801.7065)">
            <path d="M 0,0 L 0,3.378 L 5.542,3.378 L 5.542,6.495 L 0,6.495 L 0,9.591 L 7.018,9.591 L 7.018,12.859 L -4.16,12.859 L -4.16,-3.271 L 7.352,-3.271 L 7.641,0 L 0,0 z" style={{ fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
          <g transform="translate(524.7971,814.5656)">
            <path d="M 0,0 L 0,-8.815 C -0.549,-8.092 -6.7,0 -6.7,0 L -10.19,0 L -10.19,-16.13 L -6.661,-16.13 L -6.661,-6.379 C -6.106,-7.107 0.76,-16.13 0.76,-16.13 L 3.53,-16.13 L 3.53,0 L 0,0 z" style={{ fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
          <g transform="translate(539.1285,806.9666)">
            <path d="M 0,0 C 0.159,0.2 6.091,7.599 6.091,7.599 L 1.768,7.599 C 1.768,7.599 -3.007,1.408 -3.545,0.713 L -3.545,7.599 L -7.703,7.599 L -7.703,-8.531 L -3.545,-8.531 L -3.545,-1.709 C -3.007,-2.411 1.682,-8.531 1.682,-8.531 L 6.625,-8.531 C 6.625,-8.531 0.158,-0.202 0,0" style={{ fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
          </g>
        </g>
        <g transform="matrix(1.25,0,0,-1.25,-486.74334,1342.2987)">
          <g clipPath="url(#clipPath2480)">
            <g transform="translate(551.4013,801.7065)">
              <path d="M 0,0 L 0,3.378 L 5.542,3.378 L 5.542,6.495 L 0,6.495 L 0,9.591 L 7.018,9.591 L 7.018,12.859 L -4.157,12.859 L -4.157,-3.271 L 7.354,-3.271 L 7.646,0 L 0,0 z" style={{ fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
            </g>
            <g transform="translate(565.4608,811.4522)">
              <path d="M 0,0 L 1.897,0 C 3.516,0 4.034,-0.458 4.034,-1.878 C 4.034,-3.29 3.455,-3.816 1.897,-3.816 L 0,-3.816 L 0,0 z M 6.473,-7.202 C 6.015,-6.458 5.54,-5.992 5.019,-5.676 C 7.136,-4.984 8.085,-3.779 8.085,-1.66 C 8.085,1.553 6.102,3.113 2.026,3.113 L -4.162,3.113 L -4.162,-13.017 L 0,-13.017 L 0,-6.653 L 0.53,-6.653 C 1.466,-6.653 1.805,-6.901 2.537,-8.135 L 5.432,-13.017 L 9.973,-13.017 L 6.473,-7.202 z" style={{ fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
