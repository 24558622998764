import { Button, message, Select } from 'antd';
import { useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { fetcher } from '../../../extras/fetcher';
import { CourierWithErrorsInterface } from '../../../types/CourierWithErrors.interface';
import { CheckCourierResponseInterface } from '../../../types/checkCourierResponse.interface';
import { CourierCurrentInterface } from '../../../types/courierCurrent.interface';

interface Props {
  trip: number;
  courierWithErrors: CourierWithErrorsInterface[];
  handleSetData: (data: CourierCurrentInterface[]) => void;
  handleSetCourierWithErrorsData: (data: CourierWithErrorsInterface[]) => void;
}

export const SelectNumber = (props: Props) => {
  const [selected, setSelected] = useState<string>();
  const [messageApi, contextHolder] = message.useMessage();


  const saveData = async () => {

    const type = props.courierWithErrors.find((cr) => cr.nr === Number(selected))?.type;

    const data = await fetcher(`courier/add-to-file`, 'PATCH', {
      trip: props.trip,
      type,
      nr: Number(selected),
    });

    if (data.status === 403) {
      messageApi.open({
        type: 'error',
        content: 'Nie posiadasz wymaganych uprawnień!',
      });
      return;
    }

    if (data.result.error) {
      messageApi.open({
        type: 'error',
        content: data.result.error,
      });
    } else {
      messageApi.open({
        type: 'success',
        content: `Nr wysyłki został dopisany do tripa`,
      });

      // zapisujemy dane do tabeli
      const result: CheckCourierResponseInterface = data.result;

      props.handleSetData(result.couriers);
      props.handleSetCourierWithErrorsData(result.couriersWithErrors);

    }

  }



  return (
    <>
      <Select style={{width: 150}} options={props.courierWithErrors.map((cr) => ({ value: cr.nr, label: cr.nr }))} onChange={(value) => {setSelected(value)}} />
      {!!selected && <Button onClick={saveData}  icon={<SaveOutlined/>} style={{marginLeft: '10px'}}/>}
      {contextHolder}
    </>
  );
}
