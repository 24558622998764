import React, { useEffect, useState } from 'react';
import { Input, message, Modal } from 'antd';
import { fetcher } from '../../../extras/fetcher';
import { CourierCurrentInterface } from '../../../types/courierCurrent.interface';
import { CheckCourierResponseInterface } from '../../../types/checkCourierResponse.interface';
import { CourierWithErrorsInterface } from '../../../types/CourierWithErrors.interface';

interface Props {
  open : boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleSetData: (data: CourierCurrentInterface[]) => void;
  handleSetCourierWithErrorsData: (data: CourierWithErrorsInterface[]) => void;
  tripData: {
    trip: number;
    name: string;
  },
}
export const ModalAddTrip = (props: Props) => {

  interface AddTripInterface {
    name: string;
    trip: number;
    comment?: string;
  }

  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [inputValue, setInputValue] = useState<AddTripInterface>({
    comment: '',
    name: props.tripData.name ? props.tripData.name : '',
    trip: props.tripData.trip ? props.tripData.trip : 0,
  });




  const handleCancel = () => {
    props.handleSetModalOpened(false);
    setInputValue({
      comment: '',
      name: '',
      trip: 0,});
  }

  const handleOk = async () => {
    setMoveLoading(true);


    const regTrip = new RegExp('^[8-9][0-9]{5}$');

    if(!regTrip.test(String(inputValue?.trip))) {
      messageApi.open({
        type: 'error',
        content: 'Podany nr Trip jest nieprawidłowy!',
      });
      setMoveLoading(false);
      return;
    }


    if (inputValue.name === '') {
      messageApi.open({
        type: 'error',
        content: 'Nazwa nie może być pusta!',
      });
      setMoveLoading(false);
      return;
    }


    const data = await fetcher(`courier/add`,'POST', inputValue);

    if(data.status === 403) {
      messageApi.open({
        type: 'error',
        content: 'Nie posiadasz wymaganych uprawnień!',
      });
      setMoveLoading(false);
      return;
    }

    if(data.result.error) {
      messageApi.open({
        type: 'error',
        content: data.result.error,
      });
    } else {
      messageApi.open({
        type: 'success',
        content: `Trip ${inputValue} dodany.`,
      });

      setInputValue({
        comment: '',
        name: '',
        trip: 0,});

      const result: CheckCourierResponseInterface = data.result;

      props.handleSetData(result.couriers);
      props.handleSetCourierWithErrorsData(result.couriersWithErrors);
    }


    props.handleSetModalOpened(false);
    setMoveLoading(false);


    }






  const handleInputChange = (key: keyof AddTripInterface, value: string | number) => {
    setInputValue((prev) => ({...(prev || {}), [key]: value}));
  };


  useEffect(() => {
    if (props.open) {
      setInputValue({
        comment: '',
        name: props.tripData.name ? props.tripData.name : '',
        trip: props.tripData.trip ? props.tripData.trip : 0,
      });
    }
  }, [props.open]);


  return (

<>
  <Modal
    bodyStyle={{padding: '20px'}}
    title={'Dodawanie Tripa'}
    open={props.open}
    onOk={handleOk}
    onCancel={handleCancel}
    confirmLoading={moveLoading}
    style={{borderRadius: '8px'}}
  >
    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
      <label style={{marginBottom: '5px'}}>Nazwa</label>
      <Input
        value={inputValue.name}
        onChange={(e) => handleInputChange('name', e.target.value)}
        style={{width: '100%'}}
        placeholder={'Nazwa'}
      />
      <label style={{marginBottom: '5px'}}>Trip</label>
      <Input
        value={String(inputValue.trip === 0 ? '' : inputValue.trip)}
        onChange={(e) => handleInputChange('trip', Number(e.target.value.replace(/\D/g, '')))}
        style={{width: '100%'}}
        placeholder={'Trip'}
      />
      <label style={{marginBottom: '5px'}}>Komentarz</label>
      <Input
        value={inputValue.comment}
        onChange={(e) => handleInputChange('comment', e.target.value)}
        style={{width: '100%'}}
        placeholder={'Komentarz'}
      />
    </div>
  </Modal>
  {contextHolder}
</>
  );
}
