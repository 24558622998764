import { MainBreadcrumbItem } from '../../MainBreadcrumb/MainBreadcrumb';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { SelectedMenuContext } from '../../../contexts/selectedMenu.context';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { PermError } from '../NotFoundPage/PermError';
import { fetcher } from '../../../extras/fetcher';
import { CourierCurrentInterface } from '../../../types/courierCurrent.interface';
import { ColumnsType } from 'antd/es/table';
import { myNumberSorter, myStringSorter } from '../../../extras/sorters';
import { Button, Card, message, Table } from 'antd';
import { TableHeader } from '../Ikea/Stan/TableHeader';
import TextArea from 'antd/es/input/TextArea';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';

import { DeleteTrip } from './DeleteTrip';
import { ModalAddTrip } from './ModalAddTrip';
import { LogoSchenkerSVG } from './LogoSchenkerSvg';
import { LogoDhlSVG } from './LogoDhlSVG';
import { CheckCourierResponseInterface } from '../../../types/checkCourierResponse.interface';
import { CourierWithErrors } from './CourierWithErrors';
import { CourierWithErrorsInterface } from '../../../types/CourierWithErrors.interface';

import './Courier.css';
import { ModalAddTripToCourierError } from './ModalAddTripToCourierError';
import { SelectNumber } from './SelectNumber';
import { ButtonRemoveDhl } from './ButtonRemoveDhl';

interface Props {
  handleSetMainTitle: (title: string) => void;
  setBreadcrumbHandler: (items: MainBreadcrumbItem[] | null) => void;
}
export const Courier = (props: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const selectedMenuContext = useContext(SelectedMenuContext);
  const { windowHeight } = useWindowDimensions();
  const [permError, setPermError] = useState<boolean>(false);

  const [tripsMemo, setTripsMemo] = useState<string>('');
  const [namesMemo, setNamesMemo] = useState<string>('');
  const [commentsMemo, setCommentsMemo] = useState<string>('');

  const [tableData, setTableData] = useState<CourierCurrentInterface[]>([]);
  const [courierWithErrorsData, setCourierWithErrorsData] = useState<CourierWithErrorsInterface[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [modalAddTripOpened, setModalAddTripOpened] = useState<boolean>(false);

  const [handleAddTripData, setHandleAddTripData] = useState<{
    trip: number;
    name: string;
  }>({
    trip: 0,
    name: '',
  });



  const getData = async () => {

    setLoading(true);

    const data = await fetcher(`courier`);
    if(data.status === 403) {
      setPermError(true);
      return;


    }

    const result: CheckCourierResponseInterface = data.result;

    setTableData(result.couriers);

    setCourierWithErrorsData(result.couriersWithErrors);

    setLoading(false);


  }

  useEffect(() => {
    getData();
  },[]);


  useEffect(() => {
    const breadcrumbItems: MainBreadcrumbItem[]=[
      {
        href: '/',
        key: 'courier_1',
        label: 'Wysyłki Kurierskie',
      },
    ];


    props.handleSetMainTitle('Wysyłki Kurierskie');
    props.setBreadcrumbHandler(breadcrumbItems);

    selectedMenuContext?.setSelectedKey(['courier_1']);

  },[]);


// Define CSS classes for checked and unchecked states
  const checkedStyle: { [key: string]: CSSProperties } = {
    checked: { color: 'green' },
    unchecked: { color: 'red' },
  };

  const columns: ColumnsType<CourierCurrentInterface> = [
    {
      title: 'Nazwa Kontrahenta',
      width: 125,
      dataIndex: 'name',
      sorter: (a, b) => myStringSorter(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Trip',
      width: 80,
      dataIndex: 'trip',
      sorter: (a, b) => myNumberSorter(a.trip, b.trip),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Komentarz',
      width: 150,
      dataIndex: 'comment',
      sorter: (a, b) => myStringSorter(a.comment, b.comment),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        const comment = record.comment;
        const wrappedComment = comment && comment.length > 45
          ? comment.match(/.{1,45}/g)?.map((part, index) => <div key={index}>{part}</div>)
          : comment;
        return <div>{wrappedComment}</div>;
      },
    },

    {
      title: 'Typ',
      width: 80,
      dataIndex: 'type',
      align: 'center',
      sorter: (a, b) => myNumberSorter(a.type ? a.type : 0, b.type ? b.type : 0),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => record.type === 1 ? <LogoSchenkerSVG/> : record.type === 2 ? <LogoDhlSVG /> : '',
    },


    {
      title: 'Nr Wysyłki',
      width: 140,
      dataIndex: 'nr',
      sorter: (a, b) => myNumberSorter(a.nr ? a.nr : 0, b.nr ? b.nr : 0),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        if(!!value) {
          return value;
        }

        if(courierWithErrorsData.length > 0) {
          return <SelectNumber trip={record.trip} courierWithErrors={courierWithErrorsData} handleSetData={setTableData} handleSetCourierWithErrorsData={setCourierWithErrorsData} />
        }

      }
    },


    {
      title: 'Delivery',
      width: 100,
      dataIndex: 'delivery',
      sorter: (a, b) => myStringSorter(a.delivery ? a.delivery : '', b.delivery ? b.delivery : ''),
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => record.delivery ? record.delivery.split('|').map((str, index) => <div
        key={index}>{str.trim()}</div>) : '',
    },

    {
      title: 'L/LP/D',
      width: 50,
      render: (value, record) => {
        const label = record.labelPath ? <CheckOutlined style={checkedStyle.checked}/> :
          <CloseOutlined style={checkedStyle.unchecked}/>
        const lp = record.docPath ? <CheckOutlined style={checkedStyle.checked}/> :
          <CloseOutlined style={checkedStyle.unchecked}/>
        const d = record.delListId ? <CheckOutlined style={checkedStyle.checked}/> :
          <CloseOutlined style={checkedStyle.unchecked}/>

        if (record.labelPath && record.docPath && record.delListId || !record.labelPath && !record.docPath && !record.delListId) {
          return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            {label}
          </div>
        } else {
          return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            {label}
            {lp}
            {d}
          </div>
        }

      }
    },


    {
      title: 'Komputer',
      width: 100,
      dataIndex: 'computerName',
      sorter: (a, b) => myStringSorter(a.computerName ? a.computerName : '', b.computerName ? b.computerName : ''),
      sortDirections: ['descend', 'ascend'],
    },

    {
      width: 30,
      title: '',
      align: 'center',
      filterMode: 'tree',
      render: (value, record) =>  <DeleteTrip tripNr={record.trip} id={record.id} getData={getData} />

    },


    ];


  if(permError) {
    return <PermError/>
  }


  const addTripsClick = async () => {
    if(namesMemo === '' || tripsMemo === '') {
      messageApi.open({
        type: 'error',
        content: 'Nie wypełniono odpowiednich danych',
      });
      return;
    }


    const names = namesMemo.split('\n').map(name => name.trim());
    const trips = tripsMemo.split('\n').map(trip => trip.trim());
    const comments = commentsMemo.length> 1 ? commentsMemo.split('\n').map(comment => comment.trim()): [];

    if(names.length !== trips.length) {
      messageApi.open({
        type: 'error',
        content: 'Liczba linii nazw i tripów jest różna'
      });
      return;
    }

    interface SendDataInterface {
      name: string;
      trip: number;
      comment?: string;
    }



    const data: SendDataInterface[] = [];


// Validate if trips contain exactly 6 digits and if names have a minimum of 3 characters
    for (let i = 0; i < names.length; i++) {
      if (names[i] === '' && trips[i] === '' && names.length-1 === i && names.length >= 1) {

        if(trips.length === comments.length) {
          comments.splice(i, 1);
        }

        names.splice(i, 1);
        trips.splice(i, 1);


        i--; // Adjust index after removal
        continue;
      }

      const isTripValid = /^\d{6}$/.test(trips[i]);
      const isNameValid = names[i].length >= 3;

      if (!isTripValid || !isNameValid) {
        messageApi.open({
          type: 'error',
          content: 'Niepoprawny format danych: Upewnij się, że wszystkie tripy zawierają dokładnie 6 cyfr oraz nazwy mają co najmniej 3 znaki',
        });
        return;
      }
    }
    setLoading(true);


    for(let i = 0; i < names.length; i++) {

      if (names[i] !== '' && trips[i] !== '') {
        data.push({
          name: names[i],
          trip: Number(trips[i]),
          comment: comments.length > 0 ? comments[i] : '',
        });
      }
    }

    // wysyłanie danych i jodnocześnie zapis odpowiedzi do tableData

    const response = await fetcher(`courier`, 'POST', data);
    if(response.status === 403) {
      setPermError(true);
      return;
    }

    if(response.result) {
      setTableData(response.result);
      setNamesMemo('');
      setTripsMemo('');
      setCommentsMemo('');
    }

    setLoading(false);




  }

  const gridStyle: React.CSSProperties = {
    width: '33%',
    textAlign: 'left',
  };

  const handleSetAddTripData = (trip: number, name: string) => {
    setHandleAddTripData({
      trip: trip,
      name: name,
    });

    setModalAddTripOpened(true);
  }




  return <><Table
    className={'courier-table'}
    rowKey={'id'}
    title={(records) => <TableHeader getData={getData} selectedCount={0} additional={<><Button icon={<PlusOutlined />} onClick={() => setModalAddTripOpened(true)}>Dodaj dodatkowego tripa</Button><ButtonRemoveDhl getData={getData}/></>}/> } columns={columns} scroll={{ y: windowHeight *.6}} pagination={false} loading={loading} showHeader={true} bordered={true} dataSource={tableData} style={{opacity: 0.9}} size={'small'} />

    <CourierWithErrors setCourierWithErrorsData={setCourierWithErrorsData} handleSetData={setTableData} handleAddTripModalOpened={setModalAddTripOpened} handleSetAddTripData={handleSetAddTripData} courierWithErrors={courierWithErrorsData} loading={loading} getData={getData} />

    <Card title={'Dodaj Wysyłki'} className={'courier-table'} bordered={true} size={'small'}>
      <Card.Grid style={gridStyle}>
        <TextArea rows={10} placeholder="Nazwy" value={namesMemo} onChange={(event) => setNamesMemo(event.target.value)} />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <TextArea rows={10} placeholder="Tripy" value={tripsMemo} onChange={(event) => setTripsMemo(event.target.value)} />
      </Card.Grid>
      <Card.Grid style={gridStyle}>
        <TextArea rows={10} placeholder="Komentarze" value={commentsMemo} onChange={(event) => setCommentsMemo(event.target.value)} />
      </Card.Grid>
      <Card.Grid style={{...gridStyle, width: '100%'}}><Button icon={<PlusOutlined />} onClick={addTripsClick}>Dodaj Wysyłki</Button><Button icon={<PlusOutlined />} onClick={() => setModalAddTripOpened(true)}>Dodaj dodatkowego tripa</Button></Card.Grid>
    </Card>

     <ModalAddTrip handleSetCourierWithErrorsData={setCourierWithErrorsData} tripData={handleAddTripData} open={modalAddTripOpened} handleSetData={setTableData} handleSetModalOpened={setModalAddTripOpened} />
    {contextHolder}
  </>


}
