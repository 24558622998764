import React, { useContext, useState } from 'react';
import { Menu, MenuProps, MenuTheme, notification, Spin } from 'antd';
import {
  CameraOutlined,
  HomeOutlined,
  LogoutOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { TbBuildingWarehouse, TbTruckDelivery } from 'react-icons/tb';
import { MdOutlineInventory } from 'react-icons/md';
import { BsSearch } from 'react-icons/bs';
import { LoggedInContext } from '../../contexts/loggedIn.context';
import { checkRule } from '../../extras/checkRule';
import { SelectedMenuContext } from '../../contexts/selectedMenu.context';
import { SearchBox } from './SearchBox';
import './MenuLeft.css';
import { fetcher } from '../../extras/fetcher';
import Cookies from 'universal-cookie';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { AiOutlineSecurityScan } from 'react-icons/ai';

interface Props {
  collapsed: boolean,
  theme: MenuTheme,
}

export const MenuLeft = (props: Props) => {

  const [menuIkeaInclude,setMenuIkeaInclude] = useState<MenuItem[]>([]);
  const [menuSettingsInclude,setMenuSettingsInclude] = useState<MenuItem[]>([]);
  const [menuHRInclude,setMenuHRInclude] = useState<MenuItem[]>([]);
  const [menuNavInclude,setMenuNavInclude] = useState<MenuItem[]>([]);
  const [menuInboundInclude,setMenuInboundInclude] = useState<MenuItem[]>([]);
  const [menuPhotosInclude,setMenuPhotosInclude] = useState<MenuItem[]>([]);
  const [menuCourierInclude,setMenuCourierInclude] = useState<MenuItem[]>([]);
  const [info, notificationHolder] = notification.useNotification();
  const cookies = new Cookies();

  const loggedInContext = useContext(LoggedInContext);
  const selectedMenuContext = useContext(SelectedMenuContext);
  const { windowWidth } = useWindowDimensions();

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }


  const menuHR: MenuItem[] = [

    getItem('Zarządzanie HR', 'hr', <TeamOutlined />, [
        getItem(<Link to={'/person/lockers'}>Szafki</Link>, 'hr_lockers'),
        getItem(<Link to={'/person/employees'}>Pracownicy</Link>, 'hr_employees'),
    ]),
  ];

  const menuPhotos: MenuItem[] = [

    getItem(<Link to={'/photos/'}>Zdjęcia</Link>, 'photos_1', <CameraOutlined />),
  ];

  const menuCourier: MenuItem[] = [

    getItem(<Link to={'/courier/'}>Wysyłki Kurierskie</Link>, 'courier_1', <TbTruckDelivery />),
  ];


  const menuSettings: MenuItem[] = [

    getItem('Administracja', 'admin', <SettingOutlined />, [

      getItem('Użytkownicy', 'user', <TeamOutlined />, [
        getItem(<Link to={'/admin/users'}>Panel</Link>, 'user1'),
        getItem(<Link to={'/admin/users-term'}>Terminal</Link>, 'user2'),
      ]),
    ]),
  ];

  const menuNav: MenuItem[] = [

    getItem('NAV', 'nav', <TbTruckDelivery />, [
      getItem(<Link to={'/nav/list'}>Lista Wysyłek</Link>, 'nav_list'),
    ]),
  ];

  const menuInbound: MenuItem[] = [

    getItem('PRZYJĘCIE', 'inbound', <AiOutlineSecurityScan />, [
      getItem(<Link to={'/inbound/code'}>Baza Kodów</Link>, 'inbound-code'),
    ]),
  ];


  const menuIkea: MenuItem[] = [
    getItem('IKEA', 'subWarehouse', <TbBuildingWarehouse />, [
      getItem(<SearchBox />, 'trip3',<BsSearch/> ),



      ...(checkRule('a1',loggedInContext?.user?.rule!) ? [
            getItem('Wysyłki', 'trip', <TbBuildingWarehouse />, [

              getItem(<Link to={'/trip/'}>Tripy</Link>, 'tripy'),

            ]),
          ] : []),


      ...(checkRule('a3',loggedInContext?.user?.rule!) ? [
        getItem('Stan', 'subStan', <TbBuildingWarehouse />, [
          getItem(<Link to={'/stock/rack'}>Lokalizacje</Link>, 'rack'),
          getItem(<Link to={'/stock/rack-total'}>Lokalizacje 2</Link>, 'rack-total'),
          getItem(<Link to={'/stock/material'}>Materiał</Link>, 'material'),
        ]),
      ] : []),



      ...(checkRule('a5',loggedInContext?.user?.rule!) ? [
        getItem('Statystyki', 'subStats', <PieChartOutlined />, [
          getItem(<Link to={'/stats/reg'}>Rejestracja</Link>, 'stats-reg'),
          getItem(<Link to={'/stats/mag'}>Magazynowanie</Link>, 'stats-mag'),
        ]),
      ] : []),

      ...(checkRule('a6',loggedInContext?.user?.rule!) ? [
        getItem('Inwentaryzacja', 'i2', <MdOutlineInventory />)
      ] : []),
    ]),
  ];

  /// Sprawdzanie, czy User ma uprawnienia "1" do menu Ikea
  if(loggedInContext?.user?.rule) {
    if(checkRule('a0',loggedInContext.user.rule)) {
      menuIkeaInclude.length=== 0 && setMenuIkeaInclude(menuIkea);
    }
  }

  /// Sprawdzanie, czy User ma uprawnienia "1" do menu NAV
  if(loggedInContext?.user?.rule) {
    if(checkRule('c0',loggedInContext.user.rule)) {
      menuNavInclude.length=== 0 && setMenuNavInclude(menuNav);
    }
  }

  /// Sprawdzanie, czy User ma uprawnienia "1" do menu Inbound
  if(loggedInContext?.user?.rule) {
    if(checkRule('d0',loggedInContext.user.rule)) {
      menuInboundInclude.length=== 0 && setMenuInboundInclude(menuInbound);
    }
  }

  /// Sprawdzanie, czy User ma uprawnienia do Person
  if(loggedInContext?.user?.rule) {
    if(checkRule('b0',loggedInContext.user.rule)) {
      menuIkeaInclude.length=== 0 && setMenuHRInclude(menuHR);
    }
  }


  /// Sprawdzanie, czy User ma uprawnienia do Photos
  if(loggedInContext?.user?.rule) {
    if(checkRule('e0',loggedInContext.user.rule)) {
      menuPhotosInclude.length=== 0 && setMenuPhotosInclude(menuPhotos);
    }
  }

  /// Sprawdzanie, czy User ma uprawnienia do Courier
  if(loggedInContext?.user?.rule) {
    if(checkRule('e1',loggedInContext.user.rule)) {
      menuCourierInclude.length=== 0 && setMenuCourierInclude(menuCourier);
    }
  }


  /// Sprawdzanie, czy User ma uprawnienia root (9)
  if(loggedInContext?.user?.rule) {
    if(checkRule('x9',loggedInContext.user.rule)) {
      menuSettingsInclude.length=== 0 && setMenuSettingsInclude(menuSettings);
    }
  }


  const logout = async () => {


    info.open({
      message: 'Trwa wylogowywanie...',
      description:
        <><p>Trwa usuwanie danych autoryzacyjnych z systemu.</p><p>Za chwilę zostaniesz przekierowany na stronę logowania..</p><div style={{textAlign: 'center'}}><Spin size={'large'}/></div></>,
      icon: <LogoutOutlined style={{ color: '#ef343a' }} />,
      duration: 3
    });
    //await cookies.remove('token');



    await fetcher('auth/logout');

    setTimeout(() => {
      cookies.remove('token');
      loggedInContext?.setLoggedIn(false);
    },2000);

  };

  const userMenuItems: MenuItem[] = [
    getItem('Użytkownik', 'userMenu', <UserOutlined />, [
      getItem(<Link to={'/profile/settings/'}>Ustawienia</Link>, 'userMenu1', <SettingOutlined />),
      getItem(<a onClick={logout}>Wyloguj</a>, 'userMenu2', <LogoutOutlined />),
    ]),
  ];
  const menuLeftItems: MenuItem[] = [
    getItem(<Link to={'/'}>Strona Główna</Link>, '1', <HomeOutlined />),
    ...menuIkeaInclude,
    ...menuNavInclude,
    ...menuInboundInclude,
    ...menuPhotosInclude,
    ...menuCourierInclude,
    ...menuHRInclude,
    ...menuSettingsInclude,
    ...(windowWidth < 768 ? userMenuItems : []),
  ];


  const onSelectionsChanged = (x: any) => {

    if(x.key === 'trip3') {
      return;
    }

    selectedMenuContext?.setSelectedKey([x.key]);
  }


  return <Menu onSelect={onSelectionsChanged} selectedKeys={selectedMenuContext?.selectedKey} selectable={true} style={{width: props.collapsed ? 75 : undefined,  textAlign: 'left'}} theme={props.theme} mode={'inline'} items={menuLeftItems} />
}
