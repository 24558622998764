import React, { useEffect, useState } from 'react';
import { Input, message, Modal } from 'antd';
import { fetcher } from '../../../extras/fetcher';
import { CourierCurrentInterface } from '../../../types/courierCurrent.interface';
import { CheckCourierResponseInterface } from '../../../types/checkCourierResponse.interface';
import { CourierWithErrorsInterface } from '../../../types/CourierWithErrors.interface';

interface Props {
  open : boolean;
  handleSetModalOpened: (opened: boolean) => void;
  handleSetData: (data: CourierCurrentInterface[]) => void;
  handleSetCourierWithErrorsData: (data: CourierWithErrorsInterface[]) => void;
  fileData: {
    type: 1 | 2;
    nr: number;
  }
}
export const ModalAddTripToCourierError = (props: Props) => {


  const [moveLoading, setMoveLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [trip, setTrip] = useState<number>(0);




  const handleCancel = () => {
    props.handleSetModalOpened(false);
    setTrip(0);
  }

  const handleOk = async () => {
    setMoveLoading(true);


    const regTrip = new RegExp('^[8-9][0-9]{5}$');

    if(!regTrip.test(String(trip))) {
      messageApi.open({
        type: 'error',
        content: 'Podany nr Trip jest nieprawidłowy!',
      });
      setMoveLoading(false);
      return;
    }


    const data = await fetcher(`courier/add-to-file`,'PATCH', {...props.fileData, trip});

    if(data.status === 403) {
      messageApi.open({
        type: 'error',
        content: 'Nie posiadasz wymaganych uprawnień!',
      });
      setMoveLoading(false);
      return;
    }

    if(data.result.error) {
      messageApi.open({
        type: 'error',
        content: data.result.error,
      });
    } else {
      messageApi.open({
        type: 'success',
        content: `Trip ${trip} dodany.`,
      });


      setTrip(0);

      const result: CheckCourierResponseInterface = data.result;

      props.handleSetData(result.couriers);
      props.handleSetCourierWithErrorsData(result.couriersWithErrors);
    }


    props.handleSetModalOpened(false);
    setMoveLoading(false);

    }






  const handleInputChange = (trip: number) => {
    setTrip(trip);
  };



  return (

<>
  <Modal
    bodyStyle={{padding: '20px'}}
    title={'Dodawanie danych do pliku'}
    open={props.open}
    onOk={handleOk}
    onCancel={handleCancel}
    confirmLoading={moveLoading}
    style={{borderRadius: '8px'}}
  >
    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
      <label style={{marginBottom: '5px'}}>Nr wysyłki</label>
      <Input
        value={props.fileData.nr}
        style={{width: '100%'}}
        disabled={true}
      />
      <label style={{marginBottom: '5px'}}>Trip</label>
      <Input
        value={trip}
        onChange={(e) => handleInputChange(Number(e.target.value.replace(/\D/g, '')))}
        style={{width: '100%'}}
        placeholder={'Trip'}
      />
    </div>
  </Modal>
  {contextHolder}
</>
  );
}
